<template>
  <div>
    <b-card no-title>
      <b-row>
        <b-col
            xs="12"
            class="mx-auto"
        >
          <b-card-title>
            {{ $t('card.title.modify.page') }}
            <div class="float-right mr-1">
              <b-button
                  variant="danger"
                  size="sm"
                  @click="deleteEntity"
              >
                <span v-if="!this.$helpers.isMobileResolution() && !this.$helpers.isSmallResolution()"
                      class="text-nowrap">{{ $t('label.button.delete') }}</span>
                <feather-icon v-else icon="XCircleIcon"/>
              </b-button>
            </div>
          </b-card-title>
        </b-col>
      </b-row>
      <b-card-text>
        {{ $t('card.description.modify.page') }}
      </b-card-text>
    </b-card>
    <b-overlay :show="loading">
      <b-card-code>
        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col md="12">
                <b-form-group
                    :label="$t('form.title')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.title').toLowerCase()"
                      rules="required"
                      vid="title"
                  >
                    <b-form-input
                        v-model="formData.title"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('form.title').toLowerCase()"
                        @input="titleChanged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                    :label="$t('form.slug')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.slug').toLowerCase()"
                      rules="required"
                      vid="slug"
                  >
                    <b-form-input
                        v-model="formData.slug"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('form.slug').toLowerCase()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                    :label="$t('form.menuTitle')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.menuTitle').toLowerCase()"
                      rules="required"
                      vid="menuTitle"
                  >
                    <b-form-input
                        v-model="formData.menuTitle"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('form.menuTitle').toLowerCase()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                    :label="$t('form.subtitle')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.subtitle').toLowerCase()"
                      rules="required"
                      vid="subtitle"
                  >
                    <b-form-input
                        v-model="formData.subtitle"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('form.subtitle').toLowerCase()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                    :label="$t('form.content')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.content').toLowerCase()"
                      rules="required"
                      vid="pageContent"
                  >
                    <b-link
                        @click="$bvModal.show('bv-modal-post-help')"
                    >
                      <small class="text-primary cursor-pointer">* "Speciális jelölések" súgó</small>
                    </b-link>
                    <editor :value="formData.pageContent" @input="contentUpdated"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group>
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.showInTopMenu').toLowerCase()"
                      rules=""
                      vid="showInTopMenu"
                  >
                    <b-form-checkbox v-model="formData.showInTopMenu"
                                     :state="errors.length > 0 ? false:null"
                    >
                      {{ $t('form.showInTopMenu') }}
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group>
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.showInBottomMenu').toLowerCase()"
                      rules=""
                      vid="showInBottomMenu"
                  >
                    <b-form-checkbox v-model="formData.showInBottomMenu"
                                     :state="errors.length > 0 ? false:null"
                    >
                      {{ $t('form.showInBottomMenu') }}
                    </b-form-checkbox>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- submit button -->
              <b-col class="mt-1">
                <b-button
                    variant="primary"
                    type="submit"
                    class="float-right"
                    @click.prevent="submitForm"
                >
                  {{ $t('label.button.save') }}
                </b-button>
                <cancel-and-g-o-t-o-previous-page class="mr-2"/>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-code>
      <help-modal/>
    </b-overlay>
    <b-overlay
        :show="tabsLoading"
        spinner-variant="primary"
        spinner-type="grow"
        rounded="sm"
    >
      <b-card-code>
        <tabs
            ref="tabs"
            entity-type="App\Models\Page"
            :entity-id="parseInt(this.$route.params.id)"
            :attributes="this.tabsProperties"
            @refresh-tabs-data="refreshTabsData"
        />
      </b-card-code>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BCard,
  BCardTitle,
  BCardText,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BOverlay,
  BFormTextarea, BLink
} from 'bootstrap-vue'
import {
  required, email,
} from '@validations'
import vSelect from 'vue-select'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'
import tabs from '@/views/pages/_components/_tabs/_page/update.vue'
import helpModal from "@/views/pages/_components/_modal/areaCode/help.vue";
import editor from "@/views/pages/_components/_helper/editor.vue";

export default {
  components: {
    helpModal,
    BLink, BFormTextarea,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BCardText,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BOverlay,
    vSelect,
    cancelAndGOTOPreviousPage,
    editor,
    tabs,
  },
  data() {
    return {
      role: 'page',
      formData: {
        id: null,
        menuTitle: '',
        title: '',
        subtitle: '',
        slug: '',
        pageContent: '',
        showInTopMenu: false,
        showInBottomMenu: false,
      },
      tabsProperties: {
        images: [],
        files: [],
      },
      tabsLoading: false,
      loading: false,
      dataLoaded: false,
      required,
      email,
    }
  },
  created() {
    this.initPage()
  },
  methods: {
    initPage(onlyTabs = false) {
      this.dataLoaded = false
      this.$store.dispatch('fetchPage', this.$route.params.id).then(response => {
        this.processPage(response.data.entity, onlyTabs)
      }).finally(() => {
        this.dataLoaded = true
      })
    },
    processPage(page, onlyTabs = false) {
      this.tabsLoading = true

      if (!onlyTabs) {
        this.loading = false
        this.formData = page
        this.formData.id = this.$route.params.id
        this.formData.menuTitle = page.menu_title
        this.formData.pageContent = page.page_content
        this.formData.showInTopMenu = page.show_in_top_menu
        this.formData.showInBottomMenu = page.show_in_bottom_menu
        this.loading = false
      }

      this.$refs.tabs.refreshSEO()

      this.tabsProperties = {
        images: [],
        files: [],
      }

      if (page.parsed.paragraphs.length) {
        let tmp = []
        Object.keys(page.parsed.paragraphs).forEach(key => {
          tmp = [page.parsed.paragraphs[key]['data']]

          if (page.parsed.paragraphs[key]['type'] === 'image') {
            this.tabsProperties.images = [...this.tabsProperties.images, ...tmp]
          }
        })
      }

      // fileok kezelése
      if (page.parsed.files.length) {
        let tmp = []
        Object.keys(page.parsed.files).forEach(key => {
          tmp = [page.parsed.files[key]['data']]
          this.tabsProperties.files = [...this.tabsProperties.files, ...tmp]
        })
      }

      this.tabsLoading = false
    },
    titleChanged() {
      if (this.dataLoaded) {
        this.formData.slug = this.$helpers.slugify(this.formData.title)
      }
    },
    contentUpdated(value) {
      this.formData.pageContent = value
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // clone formData
          let fd = JSON.parse(JSON.stringify(this.formData))

          this.$store.dispatch('updatePage', fd).then(() => {
            this.$helpers.showSuccessToast()

            this.initPage()
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          })
        }
      })
    },
    refreshTabsData() {
      this.initPage(true)
    },
    deleteEntity() {
      this.$bvModal
          .msgBoxConfirm(this.$t('confirm.message.delete'), {
            title: this.$t('modal.title.confirm'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: this.$helpers.ucFirst(this.$t('label.yes')),
            cancelTitle: this.$helpers.ucFirst(this.$t('label.no')),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              const id = this.$route.params.id
              this.$store.dispatch('deletePage', id).then(() => {
                this.$helpers.showSuccessToast(this.$t('text.api.response.deleteSuccessfully', {id}))

                this.$acl.canListPermission(this.role)
                    ? this.$router.push({name: 'page'})
                    : this.$router.go(-1)
              })
            }
          })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
