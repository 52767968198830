var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"no-title":""}},[_c('b-row',[_c('b-col',{staticClass:"mx-auto",attrs:{"xs":"12"}},[_c('b-card-title',[_vm._v(" "+_vm._s(_vm.$t('card.title.modify.page'))+" "),_c('div',{staticClass:"float-right mr-1"},[_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":_vm.deleteEntity}},[(!this.$helpers.isMobileResolution() && !this.$helpers.isSmallResolution())?_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.$t('label.button.delete')))]):_c('feather-icon',{attrs:{"icon":"XCircleIcon"}})],1)],1)])],1)],1),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.$t('card.description.modify.page'))+" ")])],1),_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('b-card-code',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.title')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.title').toLowerCase(),"rules":"required","vid":"title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.title').toLowerCase()},on:{"input":_vm.titleChanged},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.slug')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.slug').toLowerCase(),"rules":"required","vid":"slug"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.slug').toLowerCase()},model:{value:(_vm.formData.slug),callback:function ($$v) {_vm.$set(_vm.formData, "slug", $$v)},expression:"formData.slug"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.menuTitle')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.menuTitle').toLowerCase(),"rules":"required","vid":"menuTitle"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.menuTitle').toLowerCase()},model:{value:(_vm.formData.menuTitle),callback:function ($$v) {_vm.$set(_vm.formData, "menuTitle", $$v)},expression:"formData.menuTitle"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.subtitle')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.subtitle').toLowerCase(),"rules":"required","vid":"subtitle"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.subtitle').toLowerCase()},model:{value:(_vm.formData.subtitle),callback:function ($$v) {_vm.$set(_vm.formData, "subtitle", $$v)},expression:"formData.subtitle"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.content')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.content').toLowerCase(),"rules":"required","vid":"pageContent"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-link',{on:{"click":function($event){return _vm.$bvModal.show('bv-modal-post-help')}}},[_c('small',{staticClass:"text-primary cursor-pointer"},[_vm._v("* \"Speciális jelölések\" súgó")])]),_c('editor',{attrs:{"value":_vm.formData.pageContent},on:{"input":_vm.contentUpdated}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('form.showInTopMenu').toLowerCase(),"rules":"","vid":"showInTopMenu"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.showInTopMenu),callback:function ($$v) {_vm.$set(_vm.formData, "showInTopMenu", $$v)},expression:"formData.showInTopMenu"}},[_vm._v(" "+_vm._s(_vm.$t('form.showInTopMenu'))+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('form.showInBottomMenu').toLowerCase(),"rules":"","vid":"showInBottomMenu"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.formData.showInBottomMenu),callback:function ($$v) {_vm.$set(_vm.formData, "showInBottomMenu", $$v)},expression:"formData.showInBottomMenu"}},[_vm._v(" "+_vm._s(_vm.$t('form.showInBottomMenu'))+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-1"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('label.button.save'))+" ")]),_c('cancel-and-g-o-t-o-previous-page',{staticClass:"mr-2"})],1)],1)],1)],1)],1),_c('help-modal')],1),_c('b-overlay',{attrs:{"show":_vm.tabsLoading,"spinner-variant":"primary","spinner-type":"grow","rounded":"sm"}},[_c('b-card-code',[_c('tabs',{ref:"tabs",attrs:{"entity-type":"App\\Models\\Page","entity-id":parseInt(this.$route.params.id),"attributes":this.tabsProperties},on:{"refresh-tabs-data":_vm.refreshTabsData}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }